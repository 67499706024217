<template>
  <div class="group__item-container" @click="goDetail(info)" :class="{
    'has-been-removed': !info.isMember,
    'has-been-dissovled': info.isDissolve,
    'on-pressed': longPressShow
  }" v-long-press="onLongPress">
    <div class="item__inner">
      <div class="item__child item__header">
        <p v-if="Number(info.stickCreatedAt) > 0">
          <svg-icon class="icon-stick" icon-class="icon-star"></svg-icon>
        </p>
        <p v-else>{{ queue + 1 - stickCount }}</p>
      </div>
      <div class="item__child item__group-name">
        <div class="group-info">
          <img class="cover" :src="info.cover ? info.cover : defaultBg | parseImage" alt="" />
          <p class="title">{{ info.title }}</p>
        </div>
      </div>
      <div class="item__child item__group__member-count">{{ info.timelineCounts }}</div>
      <div class="item__child item__group__sign-count">
        <div class="sign-count__inner">
          <svg-icon icon-class="share-star" class="reply-new-notice" v-if="isShowStar(info)"></svg-icon>
          <p>
            {{ info.topicPostCounts }}
          </p>
        </div>
      </div>
    </div>
    <div class="long-press-container" v-show="longPressShow">
      <div class="press-item" @click.stop="(e) => fixedTopSwitch(e, info)">{{ Number(info.stickCreatedAt) > 0 ? "取消置顶" :
        "星标置顶" }}</div>
    </div>
    <div class="long-press-mask" @click.stop="onCloseLongPress" v-show="longPressShow"></div>
    <!-- <van-swipe-cell stop-propagation
      :before-close="({ position, instance }) => operationStick({ position, instance, info })">
      <template #right v-if="info.isMember">
        <van-button class="action__bt" :class="{
          fixed: Number(info.stickCreatedAt) > 0,
        }" :loading="requesting" loading-text="">{{ Number(info.stickCreatedAt) > 0 ? "取消置顶" : "星标置顶" }}</van-button>
      </template>
    </van-swipe-cell> -->
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'c_group-item',
  props: {
    info: {
      default: null,
    },
    stickCount: {
      default: 0,
    },
    queue: {
      default: 0,
    },
  },
  data() {
    return {
      requesting: false,
      longPressShow: false,
    };
  },
  mounted() { },
  methods: {
    ...mapMutations(['clearRemovedGroup']),
    isShowStar({ userNoViewCount, topicCounts, UserTopicNoHintCount }) {
      return Number(UserTopicNoHintCount) < topicCounts && Number(userNoViewCount) > 0;
    },
    topicSize(topicsID) {
      return topicsID ? topicsID.split(',').length : 0;
    },
    goDetail(group) {
      const that = this;
      if (group.isMember) {
        that.$router.push({
          path: `/group/${group.id}`,
        });
      } else {
        const hint = group.isDissolve ? '该小组已解散。' : '您已被小组移除，请联系小组成员。';
        that.$dialog
          .confirm({
            title: hint,
            showCancelButton: true,
            confirmButtonText: '删除小组',
            cancelButtonText: '确认',
            beforeClose: (action, done) => {
              if (action === 'confirm') {
                that.$request.get(`api/user/group/delete/${group.id}`).then(() => {
                  // 同时更新本地数据
                  that.clearRemovedGroup(group.id);
                  done();
                  that.$emit('after-remove-useless', Number(group.id));
                });
              } else {
                done();
              }
            },
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    operationStick({ position, instance, info }) {
      const that = this;
      if (position === 'right') {
        that.requesting = true;
        that.$request
          .get(`api/user/group/operate-stick/${info.id}`)
          .then((res) => {
            const { data: stickAt } = res;
            that.$emit('after-operated', {
              groupID: Number(info.id),
              stickAt,
            });
          })
          .finally(() => {
            that.requesting = false;
            instance.close();
          });
      } else {
        instance.close();
      }
    },
    onLongPress() {
      this.longPressShow = true;
    },
    onCloseLongPress() {
      this.longPressShow = false;
    },
    mutedSwitch() { },
    fixedTopSwitch(e, info) {
      e.stopPropagation();
      const that = this;
      that.requesting = true;
      that.$request
        .get(`api/user/group/operate-stick/${info.id}`)
        .then((res) => {
          const { data: stickAt } = res;
          that.$emit('after-operated', {
            groupID: Number(info.id),
            stickAt,
          });
        })
        .finally(() => {
          that.requesting = false;
          that.onCloseLongPress();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.long-press-mask {
  @include long-press-mask;
}

.group__item-container {
  .item__child {
    @include selectNone;
  }
}
</style>
