<template>
  <div class="group-list-container">
    <div class="group-add-bt-container" @click="onCreateNewGroup">
      <div class="inner">
        <svg-icon icon-class="icon-plus"></svg-icon>
        <p>创建小组</p>
      </div>
    </div>
    <div class="group__list-wrapper">
      <list :uid="user ? user.uid : 0" @listLoaded="onListLoaded"></list>
    </div>
    <empty
      style="margin-top: 200px"
      v-if="ieEmpty"
      hint="这里显示创建或加入的小组"
      btn-text="创建小组"
      :click-action="onCreateNewGroup"
    >
    </empty>
    <slogan v-else text="✖ 同伴的标志 ✖"></slogan>
    <!-- 个人/小组切换 -->
    <page-switch></page-switch>
  </div>
</template>

<script>
import wxtool from '@/utils/wx-tool';
import { mapState } from 'vuex';
import { Notify } from 'vant';
import list from './_list.vue';
import slogan from '../common/_slogan.vue';
import pageSwitch from '../common/_switch.vue';
import empty from '../common/_empty.vue';

export default {
  name: 'page-group-list',
  components: {
    list,
    slogan,
    pageSwitch,
    empty,
  },
  data() {
    return {
      loading: true,
      groups: null,
      createLimit: 3,
      repayPrice: 5,
      paidInfo: null,
      ieEmpty: false,
    };
  },
  computed: {
    ...mapState(['user']),
    newly() {
      return this.user.newly;
    },
  },
  mounted() {
    this.getPaidInfo();
  },
  methods: {
    onCreateNewGroup() {
      const that = this;
      if (!that.loading) {
        const excount = this.paidInfo.length;
        if (!that.newly && that.groups.length >= that.createLimit + excount) {
          //
          that.$dialog
            .confirm({
              title: '操作提醒',
              message: '参与3个小组后，需付费创建',
              confirmButtonText: `￥${that.repayPrice}.00新建`,
              beforeClose: (action, done) => {
                if (action === 'confirm') {
                  that.$request
                    .post('api/user/pay')
                    .then((res) => {
                      const { data } = res;
                      wxtool.pay({
                        timestamp: data.timestamp,
                        nonceStr: data.nonceStr,
                        package: data.package,
                        signType: data.signType,
                        paySign: data.paySign,
                        success: (r) => {
                          console.log(r);
                          that.$router.push({
                            path: '/group/create',
                          });
                        },
                      });
                      done();
                    })
                    .catch((err) => {
                      console.log(err.message);
                      Notify({
                        type: 'danger',
                        message: err.message,
                      });
                    })
                    .finally(() => {
                      done();
                    });
                } else {
                  done();
                }
              },
            })
            .then(() => {
              console.log('确认');
            })
            .catch(() => {
              console.log('取消');
            });
        } else {
          that.$router.push({
            path: '/group/create',
          });
        }
      }
    },
    onListLoaded(lists) {
      const that = this;
      that.loading = false;
      that.groups = lists;
      that.ieEmpty = that.groups.length === 0;
    },
    getPaidInfo() {
      this.$request
        .get('api/user/pay')
        .then((res) => {
          this.paidInfo = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.group-add-bt-container {
  margin-top: 33px;
  .inner {
    @include flex-box;
    justify-content: center;
    align-items: center;
  }
}
</style>
