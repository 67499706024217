<template>
  <div class="groups-list-c-wrapper">
    <div class="group__header-container">
      <div class="header__item">排行</div>
      <div class="header__item">小组</div>
      <div class="header__item">时光↓</div>
      <div class="header__item">分享</div>
    </div>

    <div class="group__items-container">
      <van-list v-model="loading" :finished="finished" @load="onLoad" ref="groupLists" :offset="50">
        <item
          v-for="(item, index) in list"
          :key="item.id"
          :info="item"
          :stickCount="stickCount"
          :queue="index"
          @after-operated="onAfterOperated"
          @after-remove-useless="onAfterRemoveUseless"
        ></item>
      </van-list>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import item from './_item.vue';

export default {
  name: 'c_group-list-bone',
  props: ['uid'],
  components: {
    item,
  },
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      list: [],
      totalPage: 1,
    };
  },
  computed: {
    ...mapState(['localRemoveGroups']),
    stickCount() {
      const stick = this.list.filter((i) => Number(i.stickCreatedAt) > 0);
      return stick.length;
    },
  },
  mounted() {},
  methods: {
    ...mapMutations(['UPDATE_REMOVE_GROUP']),
    onLoad() {
      const that = this;
      if (that.page > that.totalPage) {
        that.finished = true;
        const finalGroupsID = that.list.map((fg) => Number(fg.id));
        const dissolveGroups = that.localRemoveGroups
          .filter((lrg) => finalGroupsID.indexOf(Number(lrg.id)) === -1)
          .map((dissolveGroup) => {
            const t = dissolveGroup;
            t.isDissolve = true;
            return t;
          });
        if (dissolveGroups.length > 0) {
          that.list.push(...dissolveGroups);
        }
      } else {
        that.$request
          .get('api/group/list', {
            page: that.page,
          })
          .then((res) => {
            const { list } = res.data;
            const meta = res.data._meta;
            // 获取当前已经被移除的小组做标记
            const UID = that.uid;
            const removedGroups = list
              .filter((g) => !g.isMember && Number(UID) !== Number(g.author_uid))
              .map((g) => {
                const t = { ...g };
                delete t.created_at;
                return t;
              });
            that.UPDATE_REMOVE_GROUP(removedGroups);

            that.list.push(...list);
            that.$emit('listLoaded', that.list);
            that.totalPage = meta.pageCount;
            if (+meta.pageCount === 0) {
              that.finished = true;
            }
            that.page += 1;
          })
          .finally(() => {
            that.loading = false;
          });
      }
    },
    onAfterOperated({ groupID, stickAt }) {
      const that = this;
      const index = that.list.findIndex((group) => Number(group.id) === groupID);
      if (index >= 0) {
        that.$set(that.list[index], 'stickCreatedAt', stickAt);
        if (stickAt > 0) {
          that.list.unshift(that.list[index]);
          that.list.splice(index + 1, 1);
        }
      }
    },
    onAfterRemoveUseless(groupID) {
      const that = this;
      console.log(groupID);
      const temp = that.list.filter((i) => Number(i.id) !== groupID);
      that.list = temp;
    },
  },
};
</script>

<style></style>
